import React from 'react'
import flogo from '../../assets/flogo.svg';
import logo from '../../assets/logo.svg';
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <>
            <div className='footer'>
                <div className="max-width-container">
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 mb-3'>
                            <Link to="/">
                                <div className='d-none d-sm-none d-md-block'>
                                    <img src={flogo} className='img-fluid' alt='f-logo' />
                                </div>
                                <div className='d-sm-block d-md-none d-lg-none d-xl-none'>
                                    <img src={logo} className='img-fluid' alt='logo' />
                                </div>
                            </Link>
                            <div className='d-flex mt-4 mb-3'>
                                <a href="https://api.whatsapp.com/send?phone=6587807190" target="_blank" aria-label="whatsapp">
                                    <i className="fa fa-whatsapp me-3" aria-hidden="true"></i>
                                </a>
                                <a href="https://in.linkedin.com/company/instrive" target="_blank" aria-label="linkedin">
                                    <i className="fa fa-linkedin me-3" aria-hidden="true"></i>
                                </a>
                                {/* <i className="fa fa-facebook" aria-hidden="true"></i> */}
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-8 my-auto'>
                            <div className='row'>
                                <div className='col-lg-3 col-md-3'>
                                    <Link to="/">
                                        <ul>
                                            <li>Home</li>
                                        </ul>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-3'>
                                    <Link to="/who-we-are">
                                        <ul>
                                            <li>Who We Are</li>
                                        </ul>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-3'>
                                    <Link to="/how-we-deliver">
                                        <ul>
                                            <li>How We Deliver</li>
                                        </ul>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-3'>
                                    <Link to="/services">
                                        <ul>
                                            <li>Services</li>
                                        </ul>
                                    </Link>
                                </div>
                                {/* <div className='col-lg-3 col-md-3'>
                                    <Link to="/products">
                                        <ul>
                                            <li>Products</li>
                                        </ul>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-3'>
                                    <Link to="/clientsuccess">
                                        <ul>
                                            <li>Clients Success</li>
                                        </ul>
                                    </Link>
                                </div> */}
                                <div className='col-lg-3 col-md-3'>
                                    <Link to="/careers">
                                        <ul>
                                            <li>Careers</li>
                                        </ul>
                                    </Link>
                                </div>
                                <div className='col-lg-3 col-md-3 mb-3'>
                                    <Link to="/proposal">
                                        <button className="border-0 letus-btn"><span>Get A Proposal</span></button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='copyright'>
                <span>Copyright <i className="fa fa-copyright" aria-hidden="true"></i> 2025<Link to="/"> Instrive</Link> All Rights Reserved.</span>
            </div>
        </>
    )
}
