import React, { useState, useMemo, useEffect, useRef } from 'react'
import Navbar from "./includes/Navbar";
import money from "../assets/careers/money.webp";
import hours from "../assets/careers/hours.webp";
import meet from "../assets/careers/meet.webp";
import hero from "../assets/careers/career.webp"
import quote from "../assets/quote.svg"
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { employeesSays, empImgs } from "./helpers/Data";
import { teams, employees } from "./helpers/Slider";

export default function Careers({ JobListData }) {

    // snackbar
    const [open, setOpen] = useState(false);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    //use form validation
    const form = useRef();
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        console.log(data);
        emailjs.sendForm('service_yx787ui', 'template_azusk6u', form.current, 'WmZrSZDWbVJ2wSHu2')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        setOpen(true);
        reset();
    };

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // Search by Filtered Input //

    // const [name, setName] = useState('');
    // const [foundJobs, setFoundJobs] = useState(JobListData);

    // const filter = (e) => {
    //     const keyword = e.target.value;
    //     if (keyword !== '') {
    //         const results = JobListData.filter((user) => {
    //             return user.title.toLowerCase().startsWith(keyword.toLowerCase());
    //         });
    //         setFoundJobs(results);
    //     } else {
    //         setFoundJobs(JobListData);
    //     }
    //     setName(keyword);
    // };

    // Search by Filtered Category Dropdown //

    const [category, setCategory] = useState([]);
    const [workType, setWorkType] = useState([]);
    const [locationList, setLocationList] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState();

    useEffect(() => {
        setCategory(JobListData);
        setWorkType(JobListData);
        setLocationList(JobListData);
    }, []);

    function getFilteredList() {
        if (!selectedCategory) {
            return category, workType, locationList;
        } else {
            return JobListData.filter((item) => item.jobCategory === selectedCategory || item.workType === selectedCategory || item.location === selectedCategory);
        }
    }

    const filteredList = useMemo(getFilteredList, [selectedCategory, category, workType, locationList]);

    function handleCategoryChange(event) {
        setSelectedCategory(event.target.value);
    }

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    return (
        <div>
            {loading ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <>
                    <div className='yellow-bg'>
                        <section className="pt-2 pb-1">
                            <Navbar />
                        </section>
                        <div className='careers-bg max-width-container'>
                            <div className='row max-sec'>
                                <div className='col-md-6 my-auto'>
                                    <h1 className='mt-3'>Join Us</h1>
                                    <div className='work-with-us'>
                                        <div className='row text-start pt-5'>
                                            <div className='col-md-4'>
                                                <img src={money} className='img-fluid' alt='money' />
                                                <p>We pride ourselves on rewarding great work with great compensation.</p>
                                            </div>
                                            <div className='col-md-4'>
                                                <img src={hours} className='img-fluid' alt='hours' />
                                                <p>Flexible hours and generous down time.</p>
                                            </div>
                                            <div className='col-md-4'>
                                                <img src={meet} className='img-fluid' alt='meet' />
                                                <p>Multi- Cultural, Safe and Inclusive Environment.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 mt-4 text-center'>
                                    <img src={hero} alt='hero' className='img-fluid'></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="max-width-container max-sec">
                        <Slider {...teams}>
                            {empImgs.map((img) => (
                                <div className='p-1'>
                                    <img src={img} className='img-fluid' alt='logos' />
                                </div>
                            ))}
                        </Slider>
                    </section>
                    <div className='job-listings'>
                        <div className='max-width-container'>
                            <h4 className='mb-4'>Job Listings</h4>
                            <div className='row mb-4'>
                                {/* <div className='col-md-3 mb-2'>
                                    <div class="input-group">
                                        <span class="input-group-text" id="basic-addon1"><img src={search} /></span>
                                        <input type="search" className="form-control" placeholder="Search by Keyword" aria-label="Username" value={name}
                                            onChange={filter} aria-describedby="basic-addon1" />
                                    </div>
                                </div> */}
                                <div className='col-md-3 mb-2'>
                                    <select class="form-select" aria-label="Default select example" onChange={handleCategoryChange}>
                                        <option selected value=''>Category</option>
                                        <option value="Developers">Developers</option>
                                        <option value="Testers">Testers</option>
                                        <option value="Administration">Administration</option>
                                    </select>
                                </div>
                                <div className='col-md-3 mb-2'>
                                    <select class="form-select" aria-label="Default select example" onChange={handleCategoryChange}>
                                        <option selected value=''>Job Types</option>
                                        <option value="Full Time">Full Time</option>
                                        <option value="Part Time">Part Time</option>
                                    </select>
                                </div>
                                <div className='col-md-3'>
                                    <select className="form-select" aria-label="Default select example"
                                        onChange={handleCategoryChange}>
                                        <option selected value=''>Location</option>
                                        <option value="Chennai">Chennai</option>
                                        <option value="Remote">Remote</option>
                                    </select>
                                </div>
                            </div>
                            {filteredList.filter(data => data.jobCategory === "Developers").map((data, index) => (
                                <>
                                    {index === 0 ? <h6>{data.jobCategory}</h6> : ''}
                                    <div className="card mb-3" key={index}>
                                        <div className="card-body card-justify">
                                            <div>
                                                <h3>{data.title}</h3>
                                                <h5>{data.description}</h5>
                                            </div>
                                            <div>
                                                <h3>Location</h3>
                                                <h5>{data.location}</h5>
                                            </div>
                                            <div>
                                                <h3>Employment Type</h3>
                                                <h5>{data.workType}</h5>
                                            </div>
                                            <div>
                                                <Link to={`/jobs/${data.slug}`}>
                                                    <button className="border-0 letus-btn mt-2"><span>Apply</span></button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}

                            {filteredList.filter(data => data.jobCategory === "Testers").map((data, index) => (
                                <>
                                    {index === 0 ? <h6>{data.jobCategory}</h6> : ''}
                                    <div class="card mb-3" key={index}>
                                        <div class="card-body card-justify">
                                            <div>
                                                <h3>{data.title}</h3>
                                                <h5>{data.description}</h5>
                                            </div>
                                            <div>
                                                <h3>Location</h3>
                                                <h5>{data.location}</h5>
                                            </div>
                                            <div>
                                                <h3>Work Type</h3>
                                                <h5>{data.workType}</h5>
                                            </div>
                                            <div>
                                                <Link to={`/jobs/${data.slug}`}>
                                                    <button class="border-0 letus-btn mt-2"><span>Apply</span></button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                    <div className='contact'>
                        <div className='max-width-container'>
                            <h5 className='mb-3'>Interested in joining us but not able to find what you are looking for? Let’s talk anyway.<br /> Write to us at <span>careers@instrive.in</span></h5>
                            <form ref={form} onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <TextField
                                            id="outlined-basic"
                                            name="name"
                                            label="Name:"
                                            variant="outlined"
                                            className='mb-3'
                                            size="small"
                                            fullWidth
                                            {...register("name", { required: "Name is required." })}
                                            error={Boolean(errors.name)}
                                            helperText={errors.name?.message}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            name="email"
                                            label="Email:"
                                            variant="outlined"
                                            className='mb-3'
                                            size="small"
                                            fullWidth
                                            {...register('email', {
                                                required: 'Email is required',
                                                pattern: {
                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: 'Please enter a valid email',
                                                },
                                            })}
                                            error={Boolean(errors.email)}
                                            helperText={errors.email?.message}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Position applied for:"
                                            variant="outlined"
                                            fullWidth
                                            className='mb-3'
                                            size="small"
                                            type='text'
                                            name="position"
                                            {...register("position", { required: "Position applied for is required." })}
                                            error={Boolean(errors.position)}
                                            helperText={errors.position?.message}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <TextField
                                            id="outlined-basic"
                                            label="Current Employer:"
                                            variant="outlined"
                                            fullWidth
                                            className='mb-3'
                                            size="small"
                                            name="currentEmployer"
                                            {...register("currentEmployer", { required: "Current Employer is required." })}
                                            error={Boolean(errors.currentEmployer)}
                                            helperText={errors.currentEmployer?.message}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Phone:"
                                            variant="outlined"
                                            fullWidth
                                            className='mb-3'
                                            size="small"
                                            type='number'
                                            name="phone"
                                            {...register("phone", { required: "Phone Number is required." })}
                                            error={Boolean(errors.phone)}
                                            helperText={errors.phone?.message}
                                        />
                                    </div>
                                </div>
                                {/* File Attachment UI */}
                                {/* <div className='row'>
                                    <div className='col-lg-6'>
                                        <div>
                                            <input  {...register("file", { required: true })}
                                                class="form-control"
                                                type="file"
                                                name="file"
                                            />
                                            {errors.picture && <p className='ms-3 file-upload-err'>File Upload is required</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-6 mt-1'>
                                        <small>CV size not more than 5 MB</small>
                                    </div>
                                </div> */}
                                <h6 className='mt-1 mb-3'>Please drop your resume at <a href='mailto:careers@instrive.in'>careers@instrive.in</a></h6>
                                <button className="btn btn-submit rounded-pill px-5 mt-2" type="submit" value="Send">Submit</button>
                            </form>
                        </div>
                    </div>
                    <div className='employees'>
                        <h4>What Our Employees Says</h4>
                        <div className='max-width-container'>
                            <div className='row justify-content-center'>
                                <div className='col-md-8'>
                                    <div className='emp-shadow'>
                                        <Slider {...employees} className='mb-5'>
                                            {employeesSays.map((item, index) => (
                                                <>
                                                    <img src={quote} className="img-fluid mb-3" alt='quote' /><div className="emp-flex">
                                                        <div className="me-4">
                                                            <img src={item.pic} alt='item' />
                                                        </div>
                                                        <div>
                                                            <p className='mt-2'>{item.data}</p>
                                                            <h6 className="text-black fw-bold">{item.name}</h6>
                                                            <h6 className="mt-2 text-black fw-bold">{item.post}</h6>
                                                        </div>
                                                    </div>
                                                </>

                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* snackbar */}
                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}>
                            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }} >
                                Your form was submitted successfully!
                            </Alert>
                        </Snackbar>
                    </Stack>
                    {/* snackbar */}
                </>
            )
            }
        </div >
    )
}
